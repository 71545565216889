import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIProperty } from '@rentcheck/types';
import {
	Column,
	Row,
	ScreenContainer,
	ScreenContent,
	ScreenContentDivider,
	ScreenContentMain,
	ScreenTitleRow,
	ScreenTitleRowDivider,
	Spacer,
	TopLink,
} from 'components';
import { NewInspectionButton } from 'components/Common';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import BuildingDetails from 'screens/building';
import CommunityDetails from 'screens/community';
import { InspectionActions, PropertiesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { colors } from 'theme';
import { Dispatch } from 'types';

import Modal, { Footer } from '../../Common/Modal';
import CreateInspectionComparisonModal from '../../inspections/Comparison/Create';
import Team from '../CreateUnit/Components/Team';

import { Button, Typography } from '@mui/material';
import MergePropertyButton from 'components/merge-property-button';
import { useStoreTeam } from 'hooks/teams';
import EditPropertyModal from './EditPropertyModal';
import PropertyInformation from './PropertyInformation';
import Content from './content';
import IntegrationStatus from './integration-status';

interface MatchParams {
	id: string;
	reportId?: string;
}

const PropertyDetails = ({ match }: RouteComponentProps<MatchParams>) => {
	const propertyId = match.params.id;

	const dispatch: Dispatch = useDispatch();
	const history = useHistory();

	const profile = useTypedSelector((state) => state.activeProfile);
	const property = useTypedSelector((state) =>
		state.properties.property?.id === propertyId
			? state.properties.property
			: null
	);
	const { team } = useStoreTeam(property?.team?.id);

	const [editModalVisible, setEditModalVisible] = useState(false);
	const [selectTeamModalVisible, setSelectTeamModalVisible] = useState(false);
	const [teamId, setTeamId] = useState<string>('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [comparisonModalVisible, setComparisonModalVisible] = useState(false);
	const [completedInspections, setCompletedInspections] = useState([]);

	const userIsAdminForProperty =
		profile && property && team?.user_role === 'admin';

	const userIsAdminForMultipleOrgs =
		profile?.organizations?.filter((o) => o.role === 'admin')?.length > 0;

	const fetchInspectionsForComparison = async () => {
		if (!property) return;

		const comparisonInspections =
			await InspectionActions.getInspectionsForComparison(property.id);

		if (comparisonInspections.length > 1) {
			setCompletedInspections(comparisonInspections);
		}
	};

	useEffect(() => {
		dispatch(PropertiesActions.get(propertyId))
			.then((property) => {
				if (!property) {
					history.replace('/404');
				}
			})
			.catch(() => history.replace('/404'));
	}, [propertyId]);

	useEffect(() => {
		if (!property) return;
		fetchInspectionsForComparison();
	}, [property]);

	if (!property) {
		return null;
	}

	const renderOrganizationName = () => {
		if (!team) {
			return;
		}

		if (!userIsAdminForMultipleOrgs || !userIsAdminForProperty) {
			return <Typography>{team.internal_label}</Typography>;
		}

		return (
			<ClickableRow
				onClick={() => {
					setSelectTeamModalVisible(true);
				}}>
				<Typography>{team.internal_label}</Typography>
				<Spacer width={2} />
				<FontAwesomeIcon icon={solid('edit')} color={colors.gray} size="xs" />
			</ClickableRow>
		);
	};

	const resetSelectTeamModal = () => {
		setSelectTeamModalVisible(false);
		if (property?.team?.id) {
			setTeamId(property.team.id);
		}
	};

	const handleSave = () => {
		if (!teamId) {
			return;
		}

		setLoading(true);

		dispatch(PropertiesActions.units.update(propertyId, { team_id: teamId }))
			.then((res: APIProperty[]) => {
				const updatedProperty = _.first(res);

				if (!updatedProperty) {
					setError(
						'There was an error updating your property, please try again.'
					);
					return;
				}

				setSelectTeamModalVisible(false);
			})
			.catch((e) => setError(e.messsage))
			.finally(() => setLoading(false));
	};

	if (property.property_type === 'Building') {
		return <BuildingDetails match={match} />;
	}

	if (property.property_type === 'Community') {
		return <CommunityDetails match={match} />;
	}

	return (
		<div>
			<TopLink linkTo="/properties" label="Back to all units" />
			<ScreenContainer>
				<ScreenTitleRow style={{ height: 'auto' }}>
					<Column>
						<Typography variant="subtitle1">{property.address}</Typography>
						<Spacer height={1} />
						<ClickableRow onClick={() => setEditModalVisible(true)}>
							<Typography>{property.city + ' ' + property.zip_code}</Typography>
							<Spacer width={2} />
							<FontAwesomeIcon
								icon={solid('edit')}
								color={colors.gray}
								size="xs"
							/>
						</ClickableRow>
						<Spacer height={1} />
						{renderOrganizationName()}
						<Spacer height={1} />
						<IntegrationStatus property={property} />
					</Column>
					<Row>
						<MergePropertyButton property={property} />
						{completedInspections.length > 0 && (
							<>
								<Button
									variant="outlined"
									color="secondary"
									onClick={() => setComparisonModalVisible(true)}>
									Compare Inspections
								</Button>
								<Spacer width={3} />
							</>
						)}
						{!match.params.reportId && (
							<NewInspectionButton property={property} />
						)}
					</Row>
				</ScreenTitleRow>
				<ScreenTitleRowDivider />
				<ScreenContent hasTitleRow>
					<PropertyInformation property={property} />
					<ScreenContentDivider hasTitleRow />
					<ScreenContentMain hasTitleRow>
						<Content property={property} />
					</ScreenContentMain>
				</ScreenContent>
			</ScreenContainer>

			{editModalVisible && (
				<EditPropertyModal
					property={property}
					section="Property Address"
					setHidden={() => setEditModalVisible(false)}
				/>
			)}

			{selectTeamModalVisible && (
				<Modal setHidden={() => resetSelectTeamModal()}>
					<Team
						filterNonAdmin
						organizationId={teamId}
						setOrganizationId={setTeamId}
					/>
					<Footer
						leftButtonTitle="Cancel"
						leftButtonOnClick={() => resetSelectTeamModal()}
						rightButtonTitle="Assign Team"
						rightButtonOnClick={handleSave}
						error={error}
						disabled={teamId === team?.id}
						loading={loading}
					/>
				</Modal>
			)}
			{comparisonModalVisible && (
				<CreateInspectionComparisonModal
					property={property}
					inspections={completedInspections}
					setModalVisible={setComparisonModalVisible}
				/>
			)}
		</div>
	);
};

const ClickableRow = styled(Row)`
	cursor: pointer;
`;

export default PropertyDetails;
