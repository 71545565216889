"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getById = exports.getAll = void 0;
const request_1 = require("../../request");
const filters_1 = require("../../utils/filters");
const getAll = (pageNumber = 0, pageSize = 20, filters, sorting, config) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    return (0, request_1.request)({
        method: 'get',
        version: 'v2',
        url: '/properties/communities',
        params: {
            page_number: pageNumber,
            page_size: pageSize,
            // filters
            search: filters.search,
            team_id: filters.teamId,
            last_completed_inspection_start_date: (0, filters_1.millisToString)((_a = filters.dateRange) === null || _a === void 0 ? void 0 : _a.start),
            last_completed_inspection_end_date: (0, filters_1.millisToString)((_b = filters.dateRange) === null || _b === void 0 ? void 0 : _b.end),
            // sorting
            sort_by: sorting.sortBy,
            sort_type: sorting.sortType,
        },
        appConfig: config,
    }).then((value) => {
        const data = value.data.data;
        const totalResults = value.data.total_results;
        return { data, totalResults };
    });
});
exports.getAll = getAll;
const getById = (id, config) => __awaiter(void 0, void 0, void 0, function* () {
    return (0, request_1.request)({
        method: 'get',
        url: `/communities/${id}`,
        appConfig: config,
    }).then((value) => value.data.data);
});
exports.getById = getById;
