"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addOrdinal = exports.addOrdinalToFloor = exports.isFloor = exports.isBedroomOrBathroom = exports.isBedroom = exports.isBathroom = exports.isHalfBathroom = exports.isFullBathroom = exports.canExistMultipleTimes = void 0;
const Constants = __importStar(require("../../constants"));
/**
 * Checks if a section can be created multiple times.
 *
 * @param sectionName the section name without ordinal
 * @returns true if the section is repeatable based on property configuration
 */
const canExistMultipleTimes = (sectionName) => [
    'Bedroom',
    'Bedrooms',
    'Full Bathroom',
    'Full Bathrooms',
    'Half Bathroom',
    'Half Bathrooms',
    'Floor',
    'Floors',
].includes(sectionName);
exports.canExistMultipleTimes = canExistMultipleTimes;
const isFullBathroom = (name) => /(\w+(st|nd|rd|th) )?Full Bathroom/.test(name);
exports.isFullBathroom = isFullBathroom;
const isHalfBathroom = (name) => /(\w+(st|nd|rd|th) )?Half Bathroom/.test(name);
exports.isHalfBathroom = isHalfBathroom;
const isBathroom = (name) => (0, exports.isHalfBathroom)(name) || (0, exports.isFullBathroom)(name);
exports.isBathroom = isBathroom;
const isBedroom = (name) => /(\w+(st|nd|rd|th) )?Bedroom/.test(name);
exports.isBedroom = isBedroom;
const isBedroomOrBathroom = (name) => (0, exports.isBathroom)(name) || (0, exports.isBedroom)(name);
exports.isBedroomOrBathroom = isBedroomOrBathroom;
const isFloor = (name) => /(\d)+(st|nd|rd|th) Floor/.test(name);
exports.isFloor = isFloor;
/**
 * Adds an ordinal to the floor section to account for repeatable sections.
 * @param number the floor number, 0 based
 * @returns the floor section + ordinal, e.g. "1st Floor", "2nd Floor"
 */
const addOrdinalToFloor = (number) => {
    /**
     * America has no concept of a ground floor, so we add 1 to the number
     * to get the correct floor name. /shrug
     */
    const floor = number + 1;
    if (floor % 10 === 1) {
        return `${floor}st Floor`;
    }
    if (floor % 10 === 2) {
        return `${floor}nd Floor`;
    }
    if (floor % 10 === 3) {
        return `${floor}rd Floor`;
    }
    return `${floor}th Floor`;
};
exports.addOrdinalToFloor = addOrdinalToFloor;
/**
 * Adds an ordinal to the section to account for repeatable sections.
 *
 * @param sectionName Floor, Bedroom, Full Bathroom, Half Bathroom
 * @param number index of the section, 0 indexed
 * @returns the updated section name, e.g. "First Floor", "Second Bedroom"
 */
const addOrdinal = (sectionName, number) => {
    if (sectionName === 'Floor') {
        return (0, exports.addOrdinalToFloor)(number);
    }
    return `${Constants.Rooms.ordinalPrefixes[number]} ${sectionName}`;
};
exports.addOrdinal = addOrdinal;
