import { ApiTeamDetails, Profile } from '@rentcheck/types';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { State as FeaturesState } from './features';

import { State as AccountSettingsState } from './account-settings';
import { State as DashboardState } from './dashboard';
import { State as FileRequestsState } from './file-requests';
import { State as InspectionTemplatesState } from './inspection-templates';
import { State as InspectionsState } from './inspections';
import { State as MaintenanceState } from './maintenance';
import { State as ModalFlowsReducerState } from './modal-flows';
import { State as PropertiesState } from './properties';
import { State as SessionOptionsState } from './session-options';
import { State as SnackbarState } from './snackbar';
import { State as SubscriptionState } from './subscription';
import { State as TeamState } from './team';
import { State as TeammatesState } from './teammates';
import { State as TeamsState } from './teams';
import { State as TenantsState } from './tenants';
import { State as UserSettingsState } from './user-settings';

export interface RootState {
	auth: { error?: string };
	activeProfile: Profile;
	authenticatedUser: Profile;
	userSettings: UserSettingsState;

	dashboard: DashboardState;

	inspections: InspectionsState;
	properties: PropertiesState;
	maintenance: MaintenanceState;

	features: FeaturesState;
	firebase: { auth: any };
	fileRequests: FileRequestsState;
	images: any;

	invites: any;
	modalFlows: ModalFlowsReducerState;
	organization?: ApiTeamDetails;
	team: TeamState;
	teams: TeamsState;
	sessionOptions: SessionOptionsState;
	snackbar: SnackbarState;
	subscription: SubscriptionState;
	accountSettings?: AccountSettingsState;
	tenants: TenantsState;
	inspectionTemplates: InspectionTemplatesState;
	teammates: TeammatesState;
}

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
