"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.propertyHasIntegrationSync = void 0;
const propertyHasIntegrationSync = (property, { appFolioIntegration, rentManagerIntegrations, }) => {
    var _a, _b, _c;
    /**
     * This function is used to determine if a property has an active integration sync
     * with a vendor in order to stop the user from deleting it, since we only have a
     * single jenark integration we're allowing those users to do whatever they want with
     * them.
     */
    if (((_a = property.sync_data) === null || _a === void 0 ? void 0 : _a.vendor) === 'jenark') {
        return true;
    }
    if (((_b = property.sync_data) === null || _b === void 0 ? void 0 : _b.vendor) === 'appfolio') {
        return !!appFolioIntegration;
    }
    if (((_c = property.sync_data) === null || _c === void 0 ? void 0 : _c.vendor) === 'rentmanager') {
        return rentManagerIntegrations === null || rentManagerIntegrations === void 0 ? void 0 : rentManagerIntegrations.some((rmi) => rmi.active);
    }
    return false;
};
exports.propertyHasIntegrationSync = propertyHasIntegrationSync;
