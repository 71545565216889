"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const request_1 = require("../../request");
const create = (params) => __awaiter(void 0, void 0, void 0, function* () {
    const maintenanaceFlags = params.flags.map((flag) => {
        var _a, _b;
        return (Object.assign(Object.assign({}, flag), { created_date: (_b = (_a = flag.created_date) === null || _a === void 0 ? void 0 : _a.toDate()) === null || _b === void 0 ? void 0 : _b.toISOString() }));
    });
    if (params.type === 'Latchel') {
        return (0, request_1.request)({
            method: 'post',
            url: `/work_orders/latchel`,
            data: {
                maintenance_flags: maintenanaceFlags,
                primary_resident_id: params.primaryResidentId,
                work_order_name: params.name,
                work_order_description: params.description,
                latchel_property_id: params.latchelPropertyId,
                vendor_instructions: params.vendorInstructions,
                property_access_instructions: params.accessInstructions,
                vendor_id: params.vendorId,
                skill: params.skill,
            },
        }).then((value) => {
            return value.data.data;
        });
    }
    if (params.type === 'Yardi') {
        return (0, request_1.request)({
            method: 'post',
            url: `/work_orders/yardi`,
            data: {
                work_order_name: params.name,
                unit_occupancy: params.occupancy,
                ok_to_enter: params.okToEnter,
                access_instructions: params.accessInstructions,
                maintenance_flags: maintenanaceFlags,
                work_order_description: params.description,
                category: params.category,
                subcategory: params.subcategory,
            },
        }).then((value) => {
            return value.data.data;
        });
    }
    if (params.type === 'AppFolio') {
        return (0, request_1.request)({
            method: 'post',
            url: `/work_orders/appfolio`,
            data: {
                work_order_name: params.name,
                ok_to_enter: params.okToEnter === 'Yes',
                maintenance_flags: maintenanaceFlags,
                work_order_description: params.description,
                priority: params.priority,
            },
        }).then((value) => {
            return value.data.data;
        });
    }
    if (params.type === 'Rent Manager') {
        return (0, request_1.request)({
            method: 'post',
            url: `/work_orders/rentmanager`,
            data: {
                work_order_name: params.name,
                ok_to_enter: params.okToEnter === 'Yes',
                maintenance_flags: maintenanaceFlags,
                work_order_description: params.description,
                work_order_priority: params.priority,
            },
        }).then((value) => {
            return value.data.data;
        });
    }
    if (params.type === 'Jenark') {
        return (0, request_1.request)({
            method: 'post',
            url: `/work_orders/jenark`,
            data: {
                work_order_name: params.name,
                work_order_code: params.workOrderCode,
                maintenance_flags: maintenanaceFlags,
                work_order_description: 'work_order_description',
            },
        }).then((value) => {
            return value.data.data;
        });
    }
});
exports.default = { create };
