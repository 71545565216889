"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const request_1 = require("../request");
/**
 * Accepts invites for the user, when no data is sent it will accept all invites
 * assigned to the user based on their email, if data is sent it will accept the
 * invite with the reference code	provided.
 *
 * @param data optional data including the reference code for the invite
 * @returns all accepted invites
 */
const acceptInvites = (data) => __awaiter(void 0, void 0, void 0, function* () {
    return (0, request_1.request)({
        method: 'patch',
        url: `/inspections/invites/accept`,
        data: data !== null && data !== void 0 ? data : {},
    }).then((value) => value.data.data);
});
exports.default = { acceptInvites };
