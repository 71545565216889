"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transferInspectionToProperty = void 0;
const request_1 = require("../request");
const transferInspectionToProperty = (inspectionId, propertyId) => __awaiter(void 0, void 0, void 0, function* () {
    const payload = {
        inspection_id: inspectionId,
        property_id: propertyId,
    };
    return (0, request_1.request)({
        method: 'post',
        url: '/admin/inspections/transfer-to-property',
        data: payload,
    }).then((value) => {
        return value.data.data;
    });
});
exports.transferInspectionToProperty = transferInspectionToProperty;
