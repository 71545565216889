"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTeamIdFromMapping = void 0;
const getTeamIdFromMapping = (teamMapping, externalTeamId) => {
    var _a, _b, _c;
    const teamId = (_b = (_a = teamMapping === null || teamMapping === void 0 ? void 0 : teamMapping[externalTeamId]) === null || _a === void 0 ? void 0 : _a.team_id) !== null && _b !== void 0 ? _b : (_c = teamMapping === null || teamMapping === void 0 ? void 0 : teamMapping.default) === null || _c === void 0 ? void 0 : _c.team_id;
    if (!teamId) {
        return undefined;
    }
    return teamId;
};
exports.getTeamIdFromMapping = getTeamIdFromMapping;
