import { Menu, MenuItem } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Rules } from '@rentcheck/biz';
import { ActionsChip, Spacer } from 'components';
import _ from 'lodash';
import {
	InspectionsActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

interface Props {
	children?: React.ReactNode | React.ReactNodeArray;
	inspectionIds: string[];
	setSelectedIds?: (ids: string[]) => void;
	archived: boolean;
}

const ActionsMenu = ({
	children,
	inspectionIds,
	setSelectedIds,
	archived,
}: Props) => {
	const dispatch: Dispatch = useDispatch();

	const inspections = useTypedSelector(
		(state) => state.inspections.inspections.data
	);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleArchive = () => {
		handleClose();

		if (inspectionIds.length === 0) {
			return;
		}

		const body =
			'Are you sure you want to archive ' +
			`${
				inspectionIds.length === 1 ? 'this inspection' : 'these inspections'
			}? ` +
			'(You can access and view archived inspections at any time from the Archived Inspection list).';

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: [body],
				body2: [],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Archive',
						color: 'error',
						variant: 'contained',
						onClick: archiveInspections,
					},
				],
			})
		);
	};

	const handleDelete = () => {
		handleClose();

		if (inspectionIds.length === 0) {
			return;
		}

		const body =
			'Are you sure you want to delete these inspections? ' +
			`${inspectionIds.length} ${
				inspectionIds.length === 1 ? 'inspection' : 'inspections'
			} ` +
			'will be deleted and this update is irreversible.';

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: [body],
				body2: [],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Delete',
						color: 'error',
						variant: 'contained',
						onClick: deleteInspections,
					},
				],
			})
		);
	};

	const handleEditDueDate = async () => {
		handleClose();

		if (inspectionIds.length === 0) {
			return;
		}

		dispatch(
			ModalFlowActions.showEditDueDateModal({
				inspections: inspections.filter((i) => inspectionIds.includes(i.id)),
				onSuccess: () => setSelectedIds?.([]),
			})
		);
	};

	const handleSendReminder = async () => {
		handleClose();

		if (inspectionIds.length === 0) {
			return;
		}

		const validInspections = inspections
			.filter((i) => inspectionIds.includes(i.id))
			.filter((i) => Rules.Inspections.canSendReminder(i));

		const invites = _.flatMap(validInspections, (i) => i.invites);

		if (validInspections.length === 0 || invites.length === 0) {
			const message =
				inspectionIds.length === 1
					? 'This inspection is not eligible to send a reminder.'
					: `None of the selected inspections are eligible to send a reminder.\n` +
						`Please review your selection and try again.`;

			dispatch(SnackbarActions.showSnackbar(message));

			return;
		}

		dispatch(
			ModalFlowActions.showSendReminderModal({
				inspections: validInspections,
				onSuccess: () => setSelectedIds?.([]),
			})
		);
	};

	const archiveInspections = async () => {
		try {
			dispatch(ModalFlowActions.setConfirmationModalLoading(true));

			for (const id of inspectionIds) {
				await dispatch(InspectionsActions.archive(id)).catch(() =>
					dispatch(SnackbarActions.showError())
				);
			}

			dispatch(ModalFlowActions.closeConfirmationModal());

			dispatch(
				SnackbarActions.showSuccess(
					`Archived ${inspectionIds.length} ${
						inspectionIds.length === 1 ? 'inspection' : 'inspections'
					} successfully!`
				)
			);

			setSelectedIds?.([]);
		} catch (e) {
			Sentry.captureException(e);
			dispatch(SnackbarActions.showError());
		}
	};

	const deleteInspections = async () => {
		dispatch(InspectionsActions.deleteInspections(inspectionIds))
			.then(() => setSelectedIds?.([]))
			.catch(() => {});
	};

	return (
		<>
			<div onClick={handleClick}>
				{children ?? (
					<ActionsChip
						ids={inspectionIds}
						openMenu={(e: React.MouseEvent<HTMLElement>) => handleClick(e)}
					/>
				)}
			</div>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={!!anchorEl}
				onClose={handleClose}>
				<MenuItem id="bulk-archive-button" onClick={handleArchive}>
					Archive
					<Spacer width={4} />
				</MenuItem>
				<MenuItem id="bulk-delete-button" onClick={handleDelete}>
					Delete
					<Spacer width={4} />
				</MenuItem>
				<MenuItem id="bulk-edit-due-date-button" onClick={handleEditDueDate}>
					Edit Due Date
					<Spacer width={4} />
				</MenuItem>
				{!archived && (
					<MenuItem id="bulk-send-reminder-button" onClick={handleSendReminder}>
						Send Reminder
						<Spacer width={4} />
					</MenuItem>
				)}
			</Menu>
		</>
	);
};

export default ActionsMenu;
