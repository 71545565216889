"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const request_1 = require("../request");
const uploadFlatfileUnits = (email, flatFileUnits) => __awaiter(void 0, void 0, void 0, function* () {
    const value = yield (0, request_1.request)({
        method: 'post',
        url: `/flatFile/units`,
        data: {
            email,
            flatFileUnits,
        },
    });
    return value.data.data;
});
const uploadFlatfileBuildings = (email, flatFileBuildings) => __awaiter(void 0, void 0, void 0, function* () {
    const value = yield (0, request_1.request)({
        method: 'post',
        url: `/flatFile/buildings`,
        data: {
            email,
            flatFileBuildings,
        },
    });
    return value.data.data;
});
const uploadFlatfileResidents = (email, flatFileTenants) => __awaiter(void 0, void 0, void 0, function* () {
    const value = yield (0, request_1.request)({
        method: 'post',
        url: `/flatFile/tenants`,
        data: {
            email,
            flatFileTenants,
        },
    });
    return value.data.data;
});
exports.default = {
    uploadFlatfileUnits,
    uploadFlatfileBuildings,
    uploadFlatfileResidents,
};
