"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LIST_EXPORT_TOPIC_VALUES = exports.FILE_REQUEST_LIST_EXPORTS_VALUES = void 0;
exports.FILE_REQUEST_LIST_EXPORTS_VALUES = [
    'BUILDINGS_LIST_EXPORT',
    'COMMUNITIES_LIST_EXPORT',
    'INSPECTION_LIST_EXPORT',
    'MAINTENANCE_REPORTS_LIST_EXPORT',
    'MAINTENANCE_FLAGS_LIST_EXPORT',
    'UNITS_LIST_EXPORT',
    'SUBSCRIPTION_REPORT_LIST_EXPORT',
];
exports.LIST_EXPORT_TOPIC_VALUES = [
    'BUILDINGS_LIST_EXPORT',
    'COMMUNITIES_LIST_EXPORT',
    'UNITS_LIST_EXPORT',
    'INSPECTION_LIST_EXPORT',
    'MAINTENANCE_REPORTS_LIST_EXPORT',
];
