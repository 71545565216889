"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const request_1 = require("../request");
const filters_1 = require("../utils/filters");
const getAll = (pageNumber = 0, pageSize = 20, filters = {}, sort = {
    sort_by: 'last_name',
    sort_type: 'ASC',
}) => __awaiter(void 0, void 0, void 0, function* () {
    const options = {
        sort,
        pagination: {
            page_size: pageSize,
            page_number: pageNumber,
        },
    };
    if (!filters.search) {
        filters.search = undefined;
    }
    return (0, request_1.request)({
        method: 'get',
        url: '/residents',
        version: 'v2',
        params: {
            page_number: pageNumber,
            page_size: pageSize,
            filters: (0, filters_1.prepareFiltersForQueryParams)(filters),
            options,
        },
    }).then((data) => {
        return data.data;
    });
});
const get = (residentId) => __awaiter(void 0, void 0, void 0, function* () {
    return (0, request_1.request)({
        method: 'get',
        url: `/residents/${residentId}`,
        version: 'v2',
    }).then((res) => {
        return res.data.data;
    });
});
exports.default = {
    getAll,
    get,
};
