import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { APIProperty, Inspection } from '@rentcheck/types';

import { InspectionsApi } from 'api';
import { Column } from 'components';
import { InspectionActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { shortDisplayDate, translateInspectionStatus } from 'utils/helpers';

import EmptyState from './EmptyState';
import DetailsTable from './table';
import PropertyDetailsSkeleton from './table/skeleton';

interface Props {
	property: APIProperty;
}

interface RowProps {
	inspections: Inspection[];
}

const InspectionRows = ({ inspections }: RowProps) => {
	const history = useHistory();

	const [startIndex, setStartIndex] = useState(0);

	const pageSize = 5;
	const headers = ['Template', 'Due Date', 'Status'];

	return (
		<DetailsTable
			headers={headers}
			length={inspections.length}
			pageSize={pageSize}
			startIndex={startIndex}
			setStartIndex={setStartIndex}>
			<TableBody>
				{inspections
					.slice(startIndex * pageSize, startIndex * pageSize + pageSize)
					.map((i) => {
						const status = translateInspectionStatus(
							i,
							!!i.rejection,
							!!i.approval
						);

						return (
							<TableRow
								key={i.id}
								sx={{ cursor: 'pointer' }}
								onClick={() => history.push(`/inspections/${i.id}`)}>
								<TableCell>
									<Column>
										<Typography>{i.inspection_template.name}</Typography>
										{i.inspection_label && (
											<Typography fontSize={12}>
												{i.inspection_label}
											</Typography>
										)}
									</Column>
								</TableCell>

								<TableCell>
									<Typography>{shortDisplayDate(i.inspection_date)}</Typography>
								</TableCell>

								<TableCell>
									<Typography>{status}</Typography>
								</TableCell>
							</TableRow>
						);
					})}
			</TableBody>
		</DetailsTable>
	);
};

const InspectionsList = ({ property }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const [inspections, setInspections] = useState<Inspection[]>([]);

	const [fetchedInspections, setFetchedInspections] = useState(false);

	useEffect(() => {
		InspectionsApi.getByPropertyId(property.id).then((i) => setInspections(i));
		dispatch(InspectionActions.requestInspections(property.id, profile)).then(
			() => setFetchedInspections(true)
		);
	}, [property]);

	if (!fetchedInspections) {
		return <PropertyDetailsSkeleton />;
	}

	const filteredInspections = inspections.filter((i) => !i.deleted_date);

	const showInspectionList = filteredInspections.length > 0;

	if (!showInspectionList) {
		return <EmptyState property={property} />;
	}

	return (
		<Column>
			<InspectionRows inspections={filteredInspections} />
		</Column>
	);
};

export default InspectionsList;
