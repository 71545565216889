"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseTemplateRoomName = exports.sortFeaturesBySection = void 0;
const Constants = __importStar(require("../../constants"));
const SectionsUtils = __importStar(require("../sections"));
const compareSectionsWithOrdinals = (a, b) => {
    /**
     * If this is a floor we extract the number from the section
     * name and compare it as usual
     */
    if (a.match(/\d+(st|nd|rd|th) Floors?/)) {
        const aNumber = parseInt(a.match(/\d+/)[0]);
        const bNumber = parseInt(b.match(/\d+/)[0]);
        return aNumber - bNumber;
    }
    /**
     * If this is something else then it's a bedroom or a bathroom
     * and it's writen with an ordinal prefix.
     * e.g.: "First Bedroom", "Second Bedroom", "Third Bedroom"
     */
    const aOrdinal = a.split(' ')[0];
    const bOrdinal = b.split(' ')[0];
    return (Constants.Rooms.ordinalPrefixes.indexOf(aOrdinal) -
        Constants.Rooms.ordinalPrefixes.indexOf(bOrdinal));
};
const compareFeaturesWithinSection = (a, b, template) => {
    const section = template.sections.find((s) => (0, exports.parseTemplateRoomName)(s.name) === a.section.name ||
        s.name === a.section.name);
    /**
     * If we can't find the section in the template we compare them by name
     */
    if (!section) {
        return a.name.localeCompare(b.name);
    }
    const aIndex = section.features.findIndex((f) => f.name === a.name);
    const bIndex = section.features.findIndex((f) => f.name === b.name);
    /**
     * If both features are missing from the template then return them
     * in alphabetical order.
     */
    if (aIndex === -1 && bIndex === -1) {
        return a.name.localeCompare(b.name);
    }
    /**
     * If only A is missing sort it to the end
     */
    if (aIndex === -1) {
        return 1;
    }
    /**
     * If only B is missing sort it to the end
     */
    if (bIndex === -1) {
        return -1;
    }
    return aIndex - bIndex;
};
const findFeatureSectionIndex = (sections, feature) => {
    return sections.findIndex((s) => s.name === feature.section.name ||
        (0, exports.parseTemplateRoomName)(s.name) === feature.section.name);
};
const sortFeaturesBySection = (features, template) => {
    return features.sort((a, b) => {
        /**
         * If both sections are the same we need to compare at the feature level instead of section level
         */
        if (a.section.name_with_ordinal === b.section.name_with_ordinal) {
            return compareFeaturesWithinSection(a, b, template);
        }
        /**
         * If they are the same section and they can happen multiple times
         * (e.g. bedrooms, bathrooms, floors) we compare them by their ordinal
         * and return the result.
         */
        if (a.section.name === b.section.name &&
            SectionsUtils.canExistMultipleTimes(a.section.name)) {
            return compareSectionsWithOrdinals(a.section.name_with_ordinal, b.section.name_with_ordinal);
        }
        const aIndex = findFeatureSectionIndex(template.sections, a);
        const bIndex = findFeatureSectionIndex(template.sections, b);
        /**
         * If both sections are missing from the template then return them
         * in alphabetical order.
         */
        if (aIndex === -1 && bIndex === -1) {
            return a.section.name_with_ordinal.localeCompare(b.section.name_with_ordinal);
        }
        /**
         * If only A is missing sort it to the end
         */
        if (aIndex === -1) {
            return 1;
        }
        /**
         * If only B is missing sort it to the end
         */
        if (bIndex === -1) {
            return -1;
        }
        return aIndex - bIndex;
    });
};
exports.sortFeaturesBySection = sortFeaturesBySection;
/**
 * Converts the template room name from plural to singular, this is to
 * account for legacy functionality where the template section name is
 * plural and the feature section name is singular.
 * @param name the section name
 * @returns the singular version of the template section name
 */
const parseTemplateRoomName = (name) => {
    if (name === 'Floors') {
        return 'Floor';
    }
    if (name === 'Full Bathrooms') {
        return 'Full Bathroom';
    }
    if (name === 'Half Bathrooms') {
        return 'Half Bathroom';
    }
    if (name === 'Bedrooms') {
        return 'Bedroom';
    }
    return name;
};
exports.parseTemplateRoomName = parseTemplateRoomName;
