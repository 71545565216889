"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const request_1 = require("../../request");
const remove = (ids) => {
    return (0, request_1.request)({
        method: 'delete',
        url: '/maintenance_reports',
        params: {
            id: ids,
        },
    }).then(() => {
        return;
    });
};
exports.default = { remove };
