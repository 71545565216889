"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const request_1 = require("../request");
const connect = (apiKey, teamIds) => {
    return (0, request_1.request)({
        method: 'post',
        url: '/latchel/integrations',
        data: {
            api_key: apiKey,
            team_ids: teamIds,
        },
    }).then((value) => {
        return value.data.data;
    });
};
const disconnect = (teamIds, apiKey) => {
    return (0, request_1.request)({
        method: 'delete',
        url: '/latchel/integrations',
        data: {
            api_key: apiKey,
            team_ids: teamIds,
        },
    }).then((value) => {
        return value.data.data;
    });
};
const getProperties = (organizationId) => __awaiter(void 0, void 0, void 0, function* () {
    let currentPage = 1;
    const properties = [];
    while (currentPage) {
        const hasMoreResults = yield (0, request_1.request)({
            method: 'get',
            url: `/latchel/${organizationId}/properties`,
            params: { page_number: currentPage },
        }).then((value) => {
            properties.push(...value.data.data);
            return !!value.data.links.next;
        });
        currentPage = hasMoreResults ? currentPage + 1 : 0;
    }
    return properties;
});
const getProperty = (organizationId, propertyId) => {
    return (0, request_1.request)({
        method: 'get',
        url: `/latchel/${organizationId}/properties/${propertyId}`,
    }).then((value) => {
        return value.data.data;
    });
};
const getResidentsForProperty = (organizationId, propertyId) => __awaiter(void 0, void 0, void 0, function* () {
    let currentPage = 1;
    const tenants = [];
    while (currentPage) {
        const hasMoreResults = yield (0, request_1.request)({
            method: 'get',
            url: `/latchel/${organizationId}/properties/${propertyId}/tenants`,
            params: { page_number: currentPage },
        }).then((value) => {
            tenants.push(...value.data.data);
            return !!value.data.links.next;
        });
        currentPage = hasMoreResults ? currentPage + 1 : 0;
    }
    return tenants;
});
const getPrioritizedVendors = (organizationId, propertyId, skill) => __awaiter(void 0, void 0, void 0, function* () {
    return (0, request_1.request)({
        method: 'get',
        url: `/latchel/prioritized_vendors/${organizationId}`,
        params: {
            latchel_property_id: propertyId,
            skill,
        },
    }).then((value) => {
        return value.data.data;
    });
});
const getIntegrations = () => __awaiter(void 0, void 0, void 0, function* () {
    return (0, request_1.request)({
        method: 'get',
        url: '/latchel/integrations',
    }).then((value) => {
        return value.data.data;
    });
});
exports.default = {
    connect,
    disconnect,
    integrations: {
        getAll: getIntegrations,
    },
    vendors: {
        getPrioritized: getPrioritizedVendors,
    },
    properties: {
        get: getProperty,
        getAll: getProperties,
    },
    residents: {
        getForProperty: getResidentsForProperty,
    },
};
