import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { APIBuilding } from '@rentcheck/types';

import { Column } from 'components';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { BuildingsApi } from '@rentcheck/api-frontend';
import FilterChip from './common/filter-chip';
import FilterPopover from './common/filter-popover';
import FilterPopoverContent from './common/filter-popover-content';

const BuildingFilter = () => {
	const dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const dashboardData = useTypedSelector((state) => state.dashboard.data);
	const dashboardFilters = useTypedSelector((state) => state.dashboard.filters);

	const [buildings, setBuildings] = useState<APIBuilding[]>([]);
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		if (!profile) {
			return;
		}

		BuildingsApi.getAll(0, 250, { search: '', teamId: [] }).then((data) =>
			setBuildings(data.data)
		);
	}, [profile]);

	if (!dashboardData) {
		return null;
	}

	if (!dashboardFilters.building) {
		return null;
	}

	const handleClick = (value: APIBuilding) => {
		dispatch({
			type: 'SET_DASHBOARD_FILTER_BUILDING',
			value: _.xor(dashboardFilters.building, [value.id]),
		});
	};

	const handleRemove = () => {
		dispatch({
			type: 'SET_DASHBOARD_FILTER_BUILDING',
			value: null,
		});
	};

	const currentValue = dashboardFilters.building;
	const title =
		currentValue.length === 0
			? 'None'
			: currentValue.length === 1
				? buildings.find((o) => o.id === currentValue[0])?.name
				: `${currentValue.length} Selected`;

	const lowercaseSearchTerm = searchTerm.toLowerCase();
	const filteredBuildings = buildings.filter((o) =>
		o.name.toLowerCase().includes(lowercaseSearchTerm)
	);

	return (
		<FilterPopover
			defaultOpen
			anchorChild={
				<FilterChip
					title={`Buildings: ${title}`}
					icon={light('building')}
					style="primary"
					endIcon={solid('close')}
					endIconOnClick={handleRemove}
				/>
			}
			contentChildren={
				<Column>
					<FilterPopoverContent.Title title="Building" />

					<FilterPopoverContent.SearchBar
						placeholder="Search Buildings"
						value={searchTerm}
						setValue={setSearchTerm}
					/>

					{filteredBuildings.map((o) => (
						<FilterPopoverContent.SelectableRow
							key={o.id}
							title={o.name}
							variant="checkbox"
							onClick={() => handleClick(o)}
							selected={currentValue.includes(o.id) ?? false}
						/>
					))}
				</Column>
			}
		/>
	);
};

export default BuildingFilter;
